
<template>
  <div class="film">
    <!--<h3>{{titre}}</h3>-->
    <img :src="afficheurl" height="auto" width="100%"/>
    <div class="directuscontent" v-html="texte"></div>
  </div>
</template>

<script>
import DirectusSDK from "@directus/sdk-js";
import directusConfig from "@/directus";

export default {
  props: ['clef'],
  data(){
    return {
      client: new DirectusSDK(directusConfig),
      texte:'',
      titre:'',
      afficheurl:''
    }
  },
  beforeMount() {
    this.load();
  },
  methods:{
    load(){
      this.client.getItems('films',{fields:'*.*.*', filter:{reference:this.clef}}).then(a => {
        if(a.data.length == 1){
          this.texte = a.data[0].texte;
          this.titre  = a.data[0].titre;
          this.afficheurl = a.data[0].affiche.data.full_url;
        }
      });
    }
  }
}
</script>

<style scoped>
.film{
  text-align: left;
}
</style>
