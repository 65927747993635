
<template>
  <div class="footerbody">
    <div class="row">
      <div class="col">
        <div class="sociallinks">
           <a href="https://www.facebook.com/Festival.Film.Subversif" rel="external" target="_blank" title="Facebook" id="lienfacebook"></a>
           <a href="https://www.instagram.com/festival_film_subversif_metz/?hl=fr" rel="external" target="_blank" title="Instagram" id="lieninsta"></a>
          <a href="https://www.helloasso.com/associations/the-bloggers-cinema-club/evenements/8e-edition-du-festival-du-film-subversif-de-metz" rel="external" target="_blank" title="Billets sur HelloAsso" id="lienhello"></a>
          <a href="https://linktr.ee/festival_film_subversif_metz?fbclid=IwAR3Bz97-cuJh3cou_Kp6Oo6KddcKmMi4jYHKi2hEYBwISpBC-4XJPtRaKcQ" rel="external" target="_blank" title="Linktree" id="lienlinktree"></a>
          <a href="https://mubi.com/promos/subversif" rel="external" target="_blank" title="Mubi" id="mubi"></a>
          </div>
        <!--<a class="tag" href="https://2021.subversif.fr/" title="Archives 2021">Archives 2021</a><br/>-->
        <p class="mentions">&#xA9; 2023 Festival du Film Subversif de Metz - Crédits illustration <a href="https://www.joskartszimmermann.com/" title="Illustrateur Artiste Indépendant | Joshua Sucré Zimmermann" rel="external" target="_blank">JOSK-ART</a> - <router-link to="/mentionslegales">Mentions légales</router-link></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>

.mentions{
  font-size:12px;
}
.row{
  margin-top:2em;
}
.tag, a{
  color:white;
  text-decoration:none;
}
.tag:hover, .tag:focus, a:hover{
  color:#959595;
  text-decoration:underline;
}
.sociallinks{
  text-align: center;
}
.sociallinks a{
  width:24px;
  height:24px;
  display:inline-block;
  margin:12px;
  
}
.sociallinks a:hover{
    background-position-y: -24px;
  }
#lienfacebook{
   background-image: url('./../assets/facebook.png');
}
#lieninsta{
   background-image: url('./../assets/insta.png');
}
#lienhello {
  background-image: url('./../assets/asso.png');
}
#lienlinktree{
   background-image: url('./../assets/linktree.png');
}
#mubi{
  background-image: url('./../assets/mubi.png');
}
</style>
