<template>
  <div id="splash">
    <div class="content container">
      <div class="title">
        <p>8<sup>e</sup> édition<br/>9-11 juin 2023</p>
      </div>
      <div class="logo">
        <!-- --------------- DEBUT LOGO -->
        <div class="logo-container">
          <img id="logoimg" v-on:click="goToHome()" :src="require(`@/assets/logo.png`)" />
        </div>

        <!-- --------------- FIN LOGO -->
      </div>
      <div class="calltoaction">

        <a href="#" class="link" v-on:click="goToHome()">
          <svg viewBox='0 0 200 200' width='200' height='200' xmlns='http://www.w3.org/2000/svg' class="link__svg"
               aria-labelledby="link1-title link1-desc" >
            <title id="link1-title">Entrer</title>

            <path id="link-circle" class="link__path" d="M 20, 100 a 80,80 0 1,1 160,0 a 80,80 0 1,1 -160,0"
                  stroke="none" fill="none"/>

            <path class="link__arrow" d="M 75 100 L 125 100 L 110 85 M 125 100 L 110 115" fill="none"/>

            <text class="link__text">
              <textPath href="#link-circle" stroke="none">
                - GET SUBVERSIVE - GET SUBVERSIVE
              </textPath>
            </text>
          </svg>
        </a>


      </div>


    </div>
  </div>
</template>

<script>

export default {
  name: 'splashscreen',
  title: 'Festival Subversif',
  methods: {
    goToHome() {
      this.$router.push('palmares2023');
    }
  }
}
</script>

<style>


</style>

<style scoped lang="scss">

#splash {
  width: 100%;
}

.calltoaction, .txt {
  font-size: 1.8em;
  font-weight: 600;
  text-transform: uppercase;
}

.title {
  margin-top: 40px;
  font-size:20px;
}

.title, .txt {
  font-family: "Josefin Sans";
  font-weight: 400;
}

.link {
  height: 10rem;
}

#logoimg{
  cursor:pointer;
}



.calltoaction {
  position: absolute;

  button {
    background: black;
    color: white;
    border: 1px solid white;
    padding: 0 15px;
    margin-bottom: 20px;

    &:hover {
      background: white;
      color: black;
    }
  }

}

$rotationDuration: 20s;
$hoverDuration: 0.3s;

$easeIn: cubic-bezier(0.32, 0, 0.67, 0);
$easeOut: cubic-bezier(0.33, 1, 0.68, 1);

$textColor: #fff;
$bgColor: #A6B8B1;
$accentColor: #E8D6C1;


.link {
  width: 10rem;
  display: inline-block;
  font: 300 1.55rem/1.4 "Inconsolata";
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.1000em;
  word-spacing: 0.01em;
  text-decoration: none;

  &__svg {
    width: 100%;
    height: auto;
    transform-box: fill-box;
    fill: $textColor;
    stroke: $textColor;
    stroke-width: 0.05em;
    stroke-linecap: round;
    stroke-linejoin: round;
  }


  &__arrow {
    transform-origin: 50% 50%;
    stroke-width: 0.075em;
    transition: transform $hoverDuration / 2 $easeIn;

    .link:hover & {
      transform: scale(1.1);
      transition: transform $hoverDuration $easeOut;
    }
  }


  &__text {
    animation: rotateReverse normal infinite $rotationDuration linear;
    transform-origin: 50% 50%;

    .link:hover & {
      animation-play-state: paused;
    }
  }

  &--alt {
    font-size: 1.15rem;
    letter-spacing: 0;
    word-spacing: 0;
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotateReverse {
  to {
    transform: rotate(-360deg);
  }
}


.logo-container {
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;

  .text {
    vertical-align: middle;
    height: 40vh;
    font-size: 12rem;
    font-family: "Josefin Sans";
    font-weight: bold;
    -webkit-text-stroke: 2px #161618;
    letter-spacing: 4px;
    user-select: none;
    pointer-events: none;

    /deep/ svg{
      max-height: 30vh;
      overflow: hidden;
      vertical-align: super;
    }

  }

  .text-container {
    z-index: 1;

  }

}


@include media-breakpoint-down(sm) {
  .text {
    width: 300px;
  }
  .container{
    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-content: space-evenly;
  }
  .title{
    margin:0;
    position:relative;
  }
  #logoimg{
    max-width: 100vw;
    position:relative;
  }
  .calltoaction{
    position:relative;
  }
}

@include media-breakpoint-up(md) {
  .text {
    width: 600px;
  }
  #logoimg{
    max-height: calc(100vh - 130px);
  }
  .calltoaction{
    top: calc(50% - 5rem);
    left: 70%;
  }
}


</style>
