<template>
  <div class="main films">
    <h1>
      Palmarès 2023
    </h1>
    <div class="banner">
      <img src="./../assets/palmares/banner.jpg"/>
    </div>
    <h2>Jury Professionnel</h2>
    <div class="movielist">
      <div class="movie">
        <img src="./../assets/palmares/luttefin.jpg"/>
        <p class="mention">Mention spéciale</p>
        <p class="titre">LA LUTTE EST UNE FIN<br/> de Arthur Thomas-Pavlowsky <br/> Le G.R.E.C </p>
      </div>
      <div class="movie">
        <img src="./../assets/palmares/fairplay.jpg"/>
        <p class="mention">Le prix du Meilleur Court-métrage</p>
        <p class="titre">FAIRPLAY<br/> de Zoel Aschbacher<br/>Yukunkun Production</p>
      </div>
      <div class="movie">
        <img src="./../assets/palmares/pity.jpg"/>
        <p class="mention">Le Grand Prix</p>
        <p class="titre">GIVE ME PITY<br/> de Amanda Kramer<br/>Alief</p>
      </div>
    </div>

    <h2>Juriy Jeune</h2>
    <div class="movielist">
      <div class="movie">
        <img src="./../assets/palmares/roberts.jpg"/>
        <p class="mention">Le prix du Meilleur Court-métrage</p>
        <p class="titre">LA FÉE DES ROBERTS<br/> de Léahn Vivier-Chapas<br/>Folivari</p>
      </div>
      <div class="movie">
        <img src="./../assets/palmares/enys.jpg"/>
        <p class="mention">Mentions spéciale</p>
        <p class="titre">ENYS MEN<br/> de Mark Jenkin<br/>Protagonist Pictures</p>
      </div>
      <div class="movie">
        <img src="./../assets/palmares/pity.jpg"/>
        <p class="mention">Mentions spéciale</p>
        <p class="titre">GIVE ME PITY<br/> de Amanda Kramer<br/>Alief</p>
      </div>
      <div class="movie">
        <img src="./../assets/palmares/mars.jpg"/>
        <p class="mention">Le prix du Meilleur Long-métrage</p>
        <p class="titre">ON DIRAIT LA PLANETE MARS<br/> de Stéphane Lafleur<br/>UFO Distribution</p>
      </div>
    </div>
  </div>

</template>
<script>
import DirectusSDK from "@directus/sdk-js";
import directusConfig from "../directus";
import {bus} from '../main'

export default {
  name: 'films',
  title: 'Festival Subversif - films',
  data() {
    return {
      client: new DirectusSDK(directusConfig),
      lmcompet: [],
      lmhorscompet: []
    }
  },
  beforeMount() {
    this.load();
  },
  methods: {
    emitCompetition() {
      bus.$emit('competition');
    },
    emitHorsCompetition() {
      bus.$emit('horscompetition');
    },
    load() {
      this.client.getItems('films', {fields: '*.*.*'}).then(a => {
        a.data.forEach(
            filmdata => {
              let film = {};
              film.titre = filmdata.titre;
              film.id = filmdata.id;
              film.ref = filmdata.reference;
              film.affiche = filmdata.affiche.data.thumbnails.filter(i => i.key === "directus-medium-crop")[0].url;
              if (filmdata.encompetition) {
                this.lmcompet.push(film);
              } else {
                this.lmhorscompet.push(film);
              }
            }
        );
      });
    }
  }
}
</script>
<style scoped lang="scss">
.banner{

  img{
    width:100%;
  }
}
.films {

  text-align: left;

  h1 {
    font-family: 'Josefin Sans';
    font-size: 1.5em;
    text-transform: uppercase;
  }

  .movielist {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 20px;
    justify-content: space-around;
    .movie{

      width:250px;
      img{
        width:250px;
      }
      .mention{
        font-style: italic;
      }
    }

  }

}
</style>
