<template>
  <div class="menu-collapsed"  v-bind:class="{ 'expanded': isExpanded }">
    <div class="titre d-inline-block d-md-none" v-on:click="toggleExpand()">
      <div class="bar-container"><div class="bar" ></div></div>
      <span>Menu</span>
    </div>
    <nav>
      <ul>
        <!--<li class="firstlevel item" v-on:cick="open('programme')"><router-link  class="navlink" to="/programme">Programme</router-link></li>-->
        <li class="firstlevel item" v-on:click="closeNav()"><router-link class="navlink" to="/palmares2023">Palmarès 2023</router-link></li>
        <li><div class="firstlevel item" v-on:click="open('programme')"><router-link  class="navlink" to="/programme">Programme</router-link></div>
          <ul v-show="opened=='programme'">
            <li class="item" v-on:click="closeNav()"><a target="_blank" :href="publicPath+'files/archives/programme-FFSM-2023.pdf'" title="Programme 2023">Programme en PDF</a></li>
          </ul>
        </li>
        <li><div class="firstlevel item" ><a href="https://www.helloasso.com/associations/the-bloggers-cinema-club/evenements/8e-edition-du-festival-du-film-subversif-de-metz" class="navlink">Billetterie</a></div>
        <li><div class="firstlevel item" v-on:click="open('festival')">Festival</div>
          <ul v-show="opened=='festival'">
            <li class="item" v-on:click="closeNav()"><router-link class="navlink" to="/edito">Edito & Equipe</router-link></li>
            <li class="item" v-on:click="closeNav()"><router-link class="navlink" to="/archives">Précédentes éditions</router-link></li>
            <li class="item" v-on:click="closeNav()"><router-link class="navlink" to="/galerie2023">Photos 2023</router-link></li>
            <li class="item" v-on:click="closeNav()"><router-link class="navlink" to="/galerie2022">Photos 2022</router-link></li>
          </ul>
        </li>
        <li><div class="firstlevel item" v-on:click="open('films')"><router-link class="navlink" to="/films">Films</router-link></div>
          <ul v-show="opened=='films'">
            <li class="item" v-on:click="closeNav()"><router-link class="navlink" to="/avantpremiere">Avant première</router-link></li>
            <li><div class="item" v-on:click="opensub('competition')">En compétition</div>
              <ul v-show="subopened=='competition'">
                <li class="item lastlevel" v-on:click="closeNav()"><router-link class="navlink" to="/enysmen">Enys men</router-link></li>
                <li class="item lastlevel" v-on:click="closeNav()"><router-link class="navlink" to="/givemepity">Give me pity!</router-link></li>
                <li class="item lastlevel" v-on:click="closeNav()"><router-link class="navlink" to="/marcelcoquillage">Marcel le coquillage</router-link></li>
                <li class="item lastlevel" v-on:click="closeNav()"><router-link class="navlink" to="/ondiraitmars">On dirait la planète mars</router-link></li>
                <li class="item lastlevel" v-on:click="closeNav()"><router-link class="navlink" to="/petrol">Petrol</router-link></li>
                <li class="item" v-on:click="closeNav()"><router-link class="navlink" to="/courtscompet">Courts métrages</router-link></li>
              </ul>
            </li>
            <li><div class="item" v-on:click="opensub('retrospective')">Rétrospective</div>
              <ul v-show="subopened=='retrospective'">
                <li class="item lastlevel" v-on:click="closeNav()"><router-link class="navlink" to="/enterthevoid">Enter the void</router-link></li>
                <li class="item lastlevel" v-on:click="closeNav()"><router-link class="navlink" to="/exorciste">L’exorciste</router-link></li>
                <li class="item lastlevel" v-on:click="closeNav()"><router-link class="navlink" to="/sixiemesens">Le sixième sens</router-link></li>
                <li class="item lastlevel" v-on:click="closeNav()"><router-link class="navlink" to="/phantom">Phantom of the paradise</router-link></li>
                <li class="item lastlevel" v-on:click="closeNav()"><router-link class="navlink" to="/virginsuicides">Virgin Suicides</router-link></li>
              </ul>
            </li>
              <li class="item" v-on:click="closeNav()"><router-link class="navlink" to="/horsformat">Hors format</router-link></li>
              <li class="item" v-on:click="closeNav()"><router-link class="navlink" to="/clips">Clips musicaux</router-link></li>
              <li class="item" v-on:click="closeNav()"><router-link class="navlink" to="/carteblanche">Carte blanche</router-link></li>
              <li class="item" v-on:click="closeNav()"><router-link class="navlink" to="/jeunepublic">Jeune Public</router-link></li>
          </ul>
        </li>
        <li class="firstlevel item" v-on:click="closeNav()"><router-link class="navlink" to="/jury">Jury et invité.e.s</router-link></li>
        <li class="firstlevel item" v-on:click="closeNav()"><router-link class="navlink" to="/evenements">Événements</router-link></li>
        <li><div class="firstlevel item" v-on:click="open('infospratiques')">Infos pratiques</div>
          <ul v-show="opened=='infospratiques'">
            <li class="item" v-on:click="closeNav()"><router-link class="navlink" to="/billetterie">Billetterie</router-link></li>
            <li class="item" v-on:click="closeNav()"><router-link class="navlink" to="/lieu">Lieux</router-link></li>
            <!--<li class="item" v-on:click="closeNav()"><router-link class="navlink" to="/contact">Contact</router-link></li>-->
          </ul>
        </li>
        <li class="firstlevel item" v-on:click="closeNav()"><router-link class="navlink" to="/partenaires">Partenaires</router-link></li>
      </ul>
    </nav>
  </div><!-- fin container-->
</template>

<script>
import { bus } from '../main'
export default {
  name: 'Navbar',
  data(){
    return {
      publicPath: process.env.BASE_URL,
      isExpanded: false,
      opened:'',
      subopened:'',
    }
  },
  created (){
    bus.$on('competition', () => {
      this.opened = 'films';
      this.subopened = 'competition';
      console.warn("JE RECOIT COMPETITION");
    });
    bus.$on('horscompetition', () => {
      this.opened = 'films';
      this.subopened = 'horscompetition';
    });
  },
  methods:{
        toggleExpand(){
          this.isExpanded = !this.isExpanded;
        },
        closeNav(){
          this.isExpanded = false;
        },
      open(catname){
          this.subopened = '';
          if(this.opened == catname){
            this.opened = '';
          }else{
            this.opened = catname;
          }
      },
      opensub(catname){
        if(this.subopened == catname){
          this.subopened = '';
        }else{
          this.subopened = catname;
        }
      }
    }

}
</script>

<style scoped lang="scss">
.menu-collapsed{
  text-align: left;
}
.titre{
  cursor: pointer;
  text-align: left;
  line-height: 32px;
  span{
    margin-left:40px;
    line-height: 32px;
    height: 32px;
    display: block;
  }
}
.bar-container{
  position:relative;
  margin-top:5px;
}
.b-a {
  transition: all .25s;
  position: absolute;
  left: 0;
  top:14px;
  content: "";
  height: 1px;
  width: 25px;
  background-color: white;
}
.b-a-expanded {
  transition: all .25s;
  top: -0px;
}

.bar {
  @extend .b-a;
  &:before {
    @extend .b-a;
    top: -8px;
  }
  &:after {
    @extend .b-a;
    top: 8px;
  }
}

nav{
  ul{
    list-style-type: none;
    padding: 0;
  }
  li ul{
    padding-left:15px;
  }
  a{
    width:100%;
    //height:35px;
    display:block;
    color:white;
  }
  a:hover, .item:hover a, .item:hover{
    text-decoration: underline;
    color:grey;
    cursor: pointer;
  }
  a.router-link-active{
    color:grey;
  }
  .firstlevel{
    text-transform: uppercase;
  }
  .lastlevel{
    font-style: italic;

  }
  .item{
    font-size:1em;
    line-height: 35px;
    font-weight: 600;
    //height: 35px;
  }

  .active{
    color:grey;
  }
}

@include media-breakpoint-down(sm) {
  nav{
    display:none;
  }
}

.expanded{
  nav{
    padding-top:15px;
    padding-left:15px;
    display:block;
    position:absolute;
    left:0;
    background-color: black;
    z-index: 100;
    width:100%;
  }
  .item{
    font-size:1.4em;
    line-height: 50px;
    padding:10px;
    height: 50px;
  }

  .bar {
    background-color: transparent;
    transition: all .25s;
    &:before {
      @extend .b-a-expanded;
      transform: rotate(45deg);
    }
    &:after {
      @extend .b-a-expanded;
      transform: rotate(-45deg);
    }
  }
}





</style>
