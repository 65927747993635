<template>
  <div class="main jury">
    <div class="directuscontent" v-html="data.content"></div>
  </div>

</template>
<script>
import DirectusSDK from "@directus/sdk-js";
import directusConfig from "../directus";
export default {
  name: 'jury',
  title:'Festival Subversif - jury',
  data(){
    return {
      client: new DirectusSDK(directusConfig),
      data:''
    }
  },
  beforeMount() {
    this.load();
  },
  methods:{
    load(){
      this.client.getItems('textes',{filter:{reference:'jury'}}).then(a => {
        if(a.data.length == 1){
          this.data = a.data[0];
        }
      });
    }
  }
}
</script>
<style scoped lang="scss">
.jury{

  text-align:left;


}
</style>
