<template>
  <div class="main">
    <h1 class="page-title">
      Galerie - Festival subversif 2023
    </h1>
    <p class="credits">Crédits photos :
      Samuel Grébil,
      <a href="https://www.carolinesenecal.com/" title="Site web de Caroline Sénécal" rel="external" target="_blank">Caroline Sénécal</a>,
      <a href="https://www.instagram.com/charlotteheulland/" title="page instagram de Charlotte Heullan" rel="external" target="_blank">Charlotte Heullan</a>
    </p>
    <div v-for="(cat, i) in categories" v-bind:key="i" class="galeries">
      <span>{{cat.titre}}</span>
      <div class="galerie">
        <div v-for="(thumb) in cat.thumb" v-bind:key="thumb.index" class="thumb">
          <img class="image"  :src="thumb.url" :key="thumb.index" @click="index = thumb.index">
        </div>
      </div><!--/ fin galerie -->
    </div><!-- fin loop -->
    <vue-gallery-slideshow :images="full" :index="index" @close="index = null"></vue-gallery-slideshow>
  </div><!-- fin main -->

</template>
<script>
import DirectusSDK from "@directus/sdk-js";
import directusConfig from "../directus";
import VueGallerySlideshow from 'vue-gallery-slideshow';

export default {
  name: 'home',
  title:'Festival Subversif',
  components:{
    VueGallerySlideshow
  },
  data(){
    return {
      full:[],
      index: null,
      client: new DirectusSDK(directusConfig),
      categories:[
        {titre:'09/06 Ouverture', key:'ouverture', thumb:[]},
        {titre:'10/06', key:'10du06', thumb:[]},
        {titre:'11/06', key:'11du06', thumb:[]},
        {titre:'Publication INVITE.E.S', key:'publiinvites', thumb:[]},
        {titre:'Publication JURY PRO', key:'publijurypro', thumb:[]},
        {titre:'Soirée FRAC', key:'soireefrac', thumb:[]},
      ]
    }
  },

  beforeMount() {
    this.loadGallery();
  },

  methods:{

    loadGallery(){
      this.categories.forEach(
          c => {
            let filter = {tags:[{'contains':'galerie'},{'contains':'2023'},{'contains':c.key}]};
            const options = {filter:filter};
            this.client.getFiles(options).then(files => this.onRecieveFiles(files, c));
          }
      );
    },

    onRecieveFiles(files, c){

      for (let i = 0; i < files.data.length; i++) {
        // on enregistre l'index car toutes les FULL sont dans le même tableau
        const index = this.full.length;
        const file = files.data[i].data;
        c.thumb.push({index:index, url:file.thumbnails.filter(i => i.key === "thumbnail-crop")[0].url})
        this.full.push({url: file.full_url, alt:'text'});
      }
    },
  }
}
</script>
<style scoped lang="scss">



.page-title{
  font-size: 1.5em;
}

.credits a{
  color:white;

  &:hover, &:focus{
    color:#959595;
  }
}

.galeries{
  margin-bottom:2em;
}
.galerie {

  display:flex;
  flex-wrap: wrap;
  justify-content: center;
  .thumb {
    margin:8px;

    @include media-breakpoint-down(xs) {

      margin:3px;

      img{
        width:160px;
        height:160px;
      }
    }
  }
}
</style>
