<template>
  <div id="app">
    <div v-bind:class="{ 'container-xl': $route.name !=='splashscreen' }">

      <header v-show="$route.name!=='splashscreen'"><Header></Header></header>

      <div v-bind:class="{ 'row': $route.name !=='splashscreen' }">
        <div class="col-md-3 col-12" v-show="$route.name!=='splashscreen'">
          <div class="menu"  v-show="$route.name!=='splashscreen'"><Navbar></Navbar></div>
        </div>
        <div v-bind:class="{ 'col-md-9 cl-12': $route.name !=='splashscreen' }">
          <main v-bind:class="{ 'container-fluid': $route.name !=='splashscreen' }"><router-view/></main>
        </div>
      </div>

      <footer  v-show="$route.name!=='splashscreen'"><Footer></Footer></footer>
    </div>

  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Navbar from '@/components/Navbar.vue';

export default {
  name: 'home',
  title:'Festival Subversif',
  components: {
    Header,
    Footer,
    Navbar
  },
  watch: {
    '$route' (to) {
        document.title = to.title ? to.title : 'Festival Subversif';
    }
  },
}
</script>
<style>
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Inter');

html{
  font-family: Inter;
}
#app {
  font-family: Inter;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  margin-top: 20px;
  background:black;
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
main{
  flex:1;
}

h1{

  font-size: 2em;
}

.directuscontent p{
  font-family: Inter;
}

.directuscontent h1 span,
.directuscontent h2 span,
.directuscontent h3 span{
  font-family: 'Josefin Sans' !important;
}

.menu{
  margin-top:10px;
  margin-bottom:10px;
}
</style>
