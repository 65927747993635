<template>
  <div class="head">
    <div class="row">
      <div class="col-md-3 col-6 align-self-start logo" v-on:click="goToHome()">
          <p>festival du film</p>
          <img alt="Logo du festival subversif" src="./../assets/logo-long-vectoriel.svg">
      </div>
      <div class="col-md-9 col-6 info">
        <div class="edition">
          8 <sup>e</sup> édition<br/>9-11 juin 2023
        </div>
        <div class="sociallinks d-none d-md-block">
          <a href="https://www.facebook.com/Festival.Film.Subversif" rel="external" target="_blank" title="Facebook" id="lienfacebook"></a>
          <a href="https://www.instagram.com/festival_film_subversif_metz/?hl=fr" rel="external" target="_blank" title="Instagram" id="lieninsta"></a>
          <a href="https://www.helloasso.com/associations/the-bloggers-cinema-club/evenements/8e-edition-du-festival-du-film-subversif-de-metz" rel="external" target="_blank" title="Billets sur HelloAsso" id="lienhello"></a>
          <a href=" https://linktr.ee/festival_film_subversif_metz?fbclid=IwAR3Bz97-cuJh3cou_Kp6Oo6KddcKmMi4jYHKi2hEYBwISpBC-4XJPtRaKcQ" rel="external" target="_blank" title="Linktree" id="lienlinktree"></a>
        </div>
      </div>
    </div><!--fin row -->
  </div><!-- fin container-->
</template>

<script>
export default {

  name: 'Header',
  methods:{
        goToHome(){
          this.$router.push('palmares2023');
        }
    }

}
</script>

<style scoped lang="scss">
.router-link-exact-active{
  text-decoration: none;
}

.head{
  margin-top:15px;
  font-family: 'Josefin Sans';
}
sup{
  top: -0.8em;
  font-size: .5em;
}
.logo{
  cursor:pointer;
  p{
    text-transform: uppercase;
    font-size:17px;
    display:block;
    margin-left:15px;
  }
  img{
    margin-top:-3em;
    max-width: 400px;
    padding-top:10px;
  }
}

.edition{
  text-align: left;
}

.info{
  display: flex;
  align-items: center;
  justify-content: end;

  div{
    margin-left:10px;
    margin-right:10px;
  }
}


.sociallinks{
  text-align: center;
}
.sociallinks a{
  width:24px;
  height:24px;
  display:inline-block;
  margin:12px;

}
.sociallinks a:hover{
  background-position-y: -24px;
}
#lienfacebook{
  background-image: url('./../assets/facebook.png');
}
#lieninsta{
  background-image: url('./../assets/insta.png');
}
#lienhello {
  background-image: url('./../assets/asso.png');
}
#lienlinktree{
  background-image: url('./../assets/linktree.png');
}
</style>
