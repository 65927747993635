<template>
  <div class="main sponsors">
    <h2>Partenaires institutionnels</h2>
    <div class="images">
      <div class="boxes" v-for="(sponsor,i) in institutionnels" v-bind:key="i">
        <a v-if="sponsor.link.length > 0"  :href="sponsor.link" rel="external" target="_blank">
          <img v-if="sponsor.link.length > 0" class="image" width="200px" :src="require(`@/assets/sponsors/institutionnels/`+sponsor.file)" />
        </a>
        <img v-if="sponsor.link.length == 0" class="image" width="200px" :src="require(`@/assets/sponsors/institutionnels/`+sponsor.file)" />
      </div>
    </div>
    <h2>Partenaires du festival</h2>
    <div class="images">
      <div class="boxes" v-for="(sponsor,i) in partenaires" v-bind:key="i">
        <a v-if="sponsor.link.length > 0"  :href="sponsor.link" rel="external" target="_blank">
          <img v-if="sponsor.link.length > 0" class="image" width="200px" :src="require(`@/assets/sponsors/partenaires/`+sponsor.file)" />
        </a>
        <img v-if="sponsor.link.length == 0" class="image" width="200px" :src="require(`@/assets/sponsors/partenaires/`+sponsor.file)" />
      </div>

    </div>
  </div>

</template>
<script>

export default {
  name: 'home',
  title:'Festival Subversif',
  data(){
    return {

      institutionnels:[
        {link:"https://metz.fr/",file:"metz.png"},
        {link:"https://www.eurometropolemetz.eu/",file:"eurometropole.png"},
        {link:"https://www.cnc.fr/",file:"cnc.png"},
        {link:"https://www.grandest.fr/",file:"grandest.png"},
        {link:"https://www.moselle.fr/",file:"moselle.png"},
        {link:"https://www.prefectures-regions.gouv.fr/grand-est/",file:"culture.png"},
      ],
      partenaires:[
        {link:"https://klubcinema.fr/",file:"klub.png"},
        {link:"https://www.ens-louis-lumiere.fr/",file:"louis_lumiere.png"},
        {link:"https://mubi.com/promos/subversif",file:"mubi.png"},
        {link:"https://www.bliiida.fr/",file:"bliiida.png"},
        {link:"http://www.fraclorraine.org/",file:"frac.png"},
        {link:"https://les-courtisans.fr/",file:"courtisans.png"},
        {link:"https://www.facebook.com/nostribus/",file:"nos_tribus.png"},
        {link:"https://lamourfood.fr/",file:"amour_food.png"},
        {link:"https://all.accor.com/hotel/0589/index.fr.shtml",file:"novotel.png"},
        {link:"https://www.facebook.com/lesmonstreuses48hducourtdemetz/",file:"48h.png"},
        {link:"https://www.helloasso.com/",file:"hello_asso.png"},
        {link:"https://www.instagram.com/bro.troismille/?hl=fr",file:"bro.png"},
        {link:"https://www.hisler.fr/",file:"hisler.png"},
        {link:"http://www.marlymages.org/",file:"marlymages.png"},
        {link:"https://kinepolis.fr/cinemas/kinepolis-st-julien-les-metz/infos/?id=3&block=1&blockcampaign=0&target=_self&0=&TheaterUUID=KMETZ",file:"kinepolis.png"},
      ]
    }
  },
  methods:{}
}
</script>
<style scoped lang="scss">
.sponsors {

  h2{
    font-size: 1.5em;

  }

  .images{
    display: flex;
    flex-wrap: wrap;
    margin:0 0 50px;
    justify-content: center;
  }
  .boxes {
    margin:5px;

    img{
      width:200px;
      height:200px;
    }
    @include media-breakpoint-down(xs) {
      img{
        width:160px;
        height:160px;
      }
    }

    &:hover img, &:focus img {
      filter: invert(1);
    }
  }

}

</style>
