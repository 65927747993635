<template>
  <div class="main horsformat">
    <h1 class="page-title">
      Hors format
    </h1>
    <div class="directuscontent" v-html="data.content"></div>
  </div>

</template>
<script>
import DirectusSDK from "@directus/sdk-js";
import directusConfig from "../directus";
export default {
  name: 'horsformat',
  title:'Festival Subversif - jury',
  data(){
    return {
      client: new DirectusSDK(directusConfig),
      data:''
    }
  },
  beforeMount() {
    this.load();
  },
  methods:{
    load(){
      this.client.getItems('textes',{filter:{reference:'horsformat'}}).then(a => {
        if(a.data.length == 1){
          this.data = a.data[0];
        }
      });
    }
  }
}
</script>
<style scoped lang="scss">
.horsformat{

  text-align:left;

  h1{
    font-family: 'Josefin Sans';
    font-size:1.5em;
  }

}
</style>
