<template>
  <div class="main films">
    <h1 >
      longs métrages en compétition
    </h1>
    <div class="movielist">
      <router-link  v-for="f in lmcompet"  v-bind:key="f.id" :to="'/'+f.ref" @click.native="emitCompetition()">
        <img :src="f.affiche" width="250px" height="auto"/>
        <div class="titrefilm">{{f.titre}}</div>
      </router-link>
    </div>
    <br/>
    <h1>courts métrages en compétition</h1>
    <router-link to="courtscompet">
      <img class="image" :src="require(`@/assets/courts.jpg`)" width="auto" height="300" />
    </router-link>
    <br/>
    <br/>
    <br/>
    <h1 >
      longs métrages hors compétition
    </h1>
    <div class="movielist">
      <router-link  v-for="f in lmhorscompet"  v-bind:key="f.id" :to="'/'+f.ref" @click.native="emitHorsCompetition()">
        <img :src="f.affiche" width="250px" height="auto"/>
        <div class="titrefilm">{{f.titre}}</div>
      </router-link>
    </div>
  </div>

</template>
<script>
import DirectusSDK from "@directus/sdk-js";
import directusConfig from "../directus";
import { bus } from '../main'
export default {
  name: 'films',
  title:'Festival Subversif - films',
  data(){
    return {
      client: new DirectusSDK(directusConfig),
      lmcompet:[],
      lmhorscompet:[]
    }
  },
  beforeMount() {
    this.load();
  },
  methods:{
    emitCompetition(){
      console.warn("JE EMIT COMPET");
      bus.$emit('competition');
    },
    emitHorsCompetition(){
      bus.$emit('horscompetition');
    },
    load(){
      this.client.getItems('films',{fields:'*.*.*'}).then(a => {
        a.data.forEach(
          filmdata => {
            let film = {};
            film.titre = filmdata.titre;
            film.id = filmdata.id;
            film.ref = filmdata.reference;
            film.affiche = filmdata.affiche.data.thumbnails.filter(i => i.key === "directus-medium-crop")[0].url;
            if(filmdata.encompetition) {
              this.lmcompet.push(film);
            }else{
              this.lmhorscompet.push(film);
            }
          }
        );
      });
    }
  }
}
</script>
<style scoped lang="scss">
.films{

  text-align:left;

  h1{
    font-family: 'Josefin Sans';
    font-size:1.5em;
    text-transform: uppercase;
  }
  .titrefilm{
    font-style: italic;
  }
  .movielist{
    display:flex;
    flex-wrap: wrap;
    width:100%;
    margin-bottom:20px;

  }
  a{
    color:white;
    padding:5px;
    img{
      border:4px solid transparent;
    }

    &:hover img{
      border-color:white;
    }
  }

}
</style>
