<template>
  <div class="main archives">
    <h1 class="page-title">
      Archives
    </h1>
    <div class="editions">
      <a v-for="edition in editions" v-bind:key="edition.year" class="edition"  target="_blank" :href="publicPath+'files/archives/' + edition.pdf" :title="'programme de l\'édition '+edition.year">
        <p>&#xE9;dition <span v-html="edition.year"></span></p>
        <img class="image" :src="require(`@/assets/archives/` + edition.image)" />
      </a>
    </div>
  </div>

</template>
<script>

export default {
  name: 'home',
  title:'Festival Subversif',
  data(){
    return {
      publicPath: process.env.BASE_URL,
      editions:[
        {year:2022, image:'2022.png', pdf:'prog-2022.pdf'},
        {year:2021, image:'2021.png', pdf:'prog-2021.pdf'},
        {year:2020, image:'2020.png', pdf:'prog-2020.pdf'},
        {year:2019, image:'2019.png', pdf:'prog-2019.pdf'},
        {year:2018, image:'2018.png', pdf:'prog-2018.pdf'},
        {year:2017, image:'2017.png', pdf:'prog-2017.pdf'},
        {year:2016, image:'2016.png', pdf:'prog-2016.pdf'},
      ]
    }
  },
  methods:{}
}
</script>
<style scoped lang="scss">
.archives{

  h1{
    font-size:1.5em;
  }


  .editions{

    margin-top:3em;
    display:flex;
    flex-wrap: wrap;
    justify-content: space-around;


    a.edition{
      text-transform: uppercase;
      color:white;
      margin:15px 0px 3px 0px;
      font-size:1em;

      p{
        margin-bottom:5px;
      }
      .image{
        width:130px;
        border:8px solid transparent;
      }

      &:hover, &:active{
        text-decoration: none !important;
        text-decoration: none;
        text-shadow: 0px 0px  5px #fff;

        .image{
          border-color:white;
        }
      }
    }

    @include media-breakpoint-up(md) {
      justify-content: center;
      a.edition {
        margin: 15px 40px 3px 0px;

        .image {
          width: 240px;
        }
      }
    }
    @include media-breakpoint-up(xl) {
      a.edition {
        .image {
          width: 210px;
        }
      }
    }

  }
}
</style>
