import Vue from 'vue'
import VueRouter from 'vue-router'
import Splashscreen from '../views/Splashscreen.vue'
import PageNotFound from '../views/PageNotFound.vue'
import Galerie2022 from '../views/Galerie2022.vue'
import Galerie2023 from '../views/Galerie2023.vue'
import mentionslegales from '../views/mentionslegales.vue'
import Archives from "@/views/Archives.vue";
import Partenaires from "@/views/Partenaires.vue";
import Programme from "@/views/Programme.vue";
import Edito from "@/views/Edito.vue";
import Jury from "@/views/Jury.vue";
import Evenements from "@/views/Evenements.vue";
import Billetterie from "@/views/Billetterie.vue";
import Lieu from "@/views/Lieu.vue";
import Contact from "@/views/Contact.vue";
import Films from "@/views/Films.vue";
import Enysmen from "@/views/films/Enysmen.vue";
import Givemepity from "@/views/films/Givemepity.vue";
import Marcelcoquillage from "@/views/films/Marcelcoquillage.vue";
import Ondiraitmars from "@/views/films/Ondiraitmars.vue";
import Petrol from "@/views/films/Petrol.vue";
import CourtsCompet from "@/views/CourtsCompet.vue";
import Enterthevoid from "@/views/films/Enterthevoid.vue";
import Exorciste from "@/views/films/Exorciste.vue";
import Sixiemesens from "@/views/films/Sixiemesens.vue";
import Phantom from "@/views/films/Phantom.vue";
import Virginsuicides from "@/views/films/Virginsuicides.vue";
import Horsformat from "@/views/Horsformat.vue";
import Clips from "@/views/Clips.vue";
import Carteblanche from "@/views/Carteblanche.vue";
import Jeunepublic from "@/views/Jeunepublic.vue";
import Avantpremiere from "@/views/Avantpremiere.vue";
import Palmares2023 from "@/views/Palmares2023.vue";


/**
 * Attention, bien regarder https://router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations 
 * pour que l'accès à monsite.com/mapage fonctionne en direct (conf nginx & apache) 
 */

 Vue.use(VueRouter)

 const routes = [
 {
   path: '/',
   name: 'splashscreen',
   component: Splashscreen
 },
 {
     path: '/programme',
     name: 'Programme',
     component: Programme
 },
 {
     path: '/palmares2023',
     name: 'Palmares2023',
     component: Palmares2023
 },
 {
     path: '/edito',
     name: 'Edito',
     component: Edito
 },
 {
     path: '/jury',
     name: 'Jury',
     component: Jury
 },
     {
         path: '/billetterie',
         name: 'Billetterie',
         component: Billetterie
     },
     {
         path: '/lieu',
         name: 'Lieu',
         component: Lieu
     },
     {
         path: '/contact',
         name: 'contact',
         component: Contact
     },
     {
         path: '/films',
         name: 'films',
         component: Films
     },
     // FILMS COMPET
     {
         path: '/enysmen',
         name: 'enysmen',
         component: Enysmen
     },
     {
         path: '/givemepity',
         name: 'givemepity',
         component: Givemepity
     },
     {
         path: '/marcelcoquillage',
         name: 'marcelcoquillage',
         component: Marcelcoquillage
     },
     {
         path: '/ondiraitmars',
         name: 'ondiraitmars',
         component: Ondiraitmars
     },
     {
         path: '/petrol',
         name: 'petrol',
         component: Petrol
     },
     {
         path: '/courtscompet',
         name: 'courtscompet',
         component: CourtsCompet
     },
     // FILMS HORS COMPET
     {
         path: '/enterthevoid',
         name: 'enterthevoid',
         component: Enterthevoid
     },
     {
         path: '/exorciste',
         name: 'exorciste',
         component: Exorciste
     },
     {
         path: '/sixiemesens',
         name: 'sixiemesens',
         component: Sixiemesens
     },
     {
         path: '/enterthevoid',
         name: 'enterthevoid',
         component: Enterthevoid
     },
     {
         path: '/phantom',
         name: 'phantom',
         component: Phantom
     },
     {
         path: '/virginsuicides',
         name: 'virginsuicides',
         component: Virginsuicides
     },
     // AUTRES
     {
         path: '/evenements',
         name: 'Evenements',
         component: Evenements
     },
     {
         path: '/horsformat',
         name: 'Horsformat',
         component: Horsformat
     },
     {
         path: '/avantpremiere',
         name: 'Avantpremiere',
         component: Avantpremiere
     },
     {
         path: '/clips',
         name: 'clips',
         component: Clips
     },
     {
         path: '/carteblanche',
         name: 'carteblanche',
         component: Carteblanche
     },
     {
         path: '/jeunepublic',
         name: 'jeunepublic',
         component: Jeunepublic
     },
 {
     path: '/galerie2022',
     name: 'Galerie 2022',
     component: Galerie2022
 },
 {
    path: '/galerie2023',
    name: 'Galerie 2023',
    component: Galerie2023
},
 {
     path: '/archives',
     name: 'Archives',
     component: Archives
 },
 {
     path: '/partenaires',
     name: 'Partenaires',
     component: Partenaires
 },
{
  path: '/mentionslegales',
  name: 'Mentions Legales',
  component: mentionslegales
},
 { path: "*", component: PageNotFound }
]

const router = new VueRouter({
 mode: 'history',
 routes,
 scrollBehaviors() {
   return { x: 0, y: 0 }
 }
})

export default router
